<template>
  <b-modal
    v-model="getIsShowModal"
    hide-footer
  >
    <BillingForm />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

import BillingForm from './BillingForm.vue'

export default {
  components: {
    BModal,
    BillingForm,
  },
  computed: {
    getIsShowModal: {
      get() {
        return this.$store.state.isAddCardModalShow
      },
      set(newVal) {
        this.$store.commit('SET_IS_ADD_CARD', newVal)
      },
    },
  },
}
</script>

<style>

</style>
