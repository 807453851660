<template>
  <validation-observer
    ref="officeForm"
    #default="{}"
  >
    <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
      <div>
        <h3 class="tabTitle">
          Office Information</h3>
        <b-form-group
          label="Office Name"
          label-for="office-name"
        >
          <validation-provider
            #default="{ errors }"
            name="Office Name"
            rules="required"
          >
            <v-select
              v-model="selected"
              :get-option-label="option => option.officeName"
              :options="getOfficeResOptions"
              :filterable="false"
              taggable
              disabled
              @search="onSearch"
              @input="setOffice"
            >
              <template slot="no-options">
                <span>
                  type to search for offices
                </span>
              </template>
              <template
                slot="option"
                slot-scope="officeOptions"
              >
                <div class="d-center">
                  <p>{{ officeOptions.officeName }}</p>
                  <p v-if="officeOptions.officeShortName && officeOptions.officePhone">
                    {{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}
                  </p>
                </div>
              </template>
              <template
                slot="selected-option"
                slot-scope="officeOptions"
              >
                <div class="selected d-center">
                  <p>{{ officeOptions.officeName }}
                    <span v-if="Object.keys(officeOptions).length && officeOptions.officeShortName">
                      | {{ officeOptions.officeShortName }}
                    </span>
                  </p>
                </div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone"
            rules=""
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="phone-office"
                v-model="office.phone"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="phone"
                readonly
              />
              <!-- :readonly="isPhoneReadOnly()" -->
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Fax"
          label-for="fax"
        >
          <validation-provider
            #default="{ errors }"
            name="fax"
            rules=""
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="fax"
                v-model="office.fax"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="fax"
                readonly
              />
              <!-- :readonly="isFaxReadOnly()" -->
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- <b-form-group
                  label="Office ID"
                  label-for="office-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="office-id"
                    rules=""
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="officeId"
                        v-model="office.officeId"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="office-id"
                        readonly
                      />
                    </b-input-group><small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

        <!-- v-if="isShowOfficeInput()" -->
        <b-form-group
          label="Address"
          label-for="office-address"
        >
          <validation-provider
            #default="{ errors }"
            name="office-address"
            rules=""
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="officeAddress"
                v-model="office.address"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="office-address"
                readonly
              />
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- <div class="saveBtnContainer">
                  <b-button
                    type="submit"
                    class="saveBtn"
                    variant="primary"
                    @click="reqUpdate('Request office update', 'Account', 'change_office')"
                  >
                    Request Office Update
                  </b-button>
                </div> -->

        <!-- <div class="saveBtnContainer">
          <b-button
            type="submit"
            class="saveBtn"
            :disabled="invalid"
            variant="outline-primary"
            @click="saveOffice"
          >
            save
          </b-button>
        </div> -->
      </div>
    </div>

  </validation-observer>
</template>

<script>
import {
  BFormGroup, BInputGroup, BFormInput, // BFormFile, BAvatar, BDropdown,
  // BDropdownItem, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    // BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    selectedOffice: Object,
    office: Object,
  },
  data() {
    return {
      required,
      email,
      officeOptions: [],
      selected: {},
    }
  },
  computed: {
    getOfficeResOptions() {
      return this.officeOptions
    },
  },
  watch: {
    selectedOffice: {
      deep: true,
      handler() {
        this.selected = this.selectedOffice
      },
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.getOfficeOptions(search, loading)
      } else {
        this.officeOptions = []
        loading(false)
      }
    },
    getOfficeOptions(search, loading) {
      this.$store.dispatch('offices/getOfficeNames', { key: search }).then(response => {
        if (response.code === 200) {
          loading(false)
          this.officeOptions = response.data
        }
      }).catch(err => {
        console.error(err)
      })
    },
    setOffice() {
      if (this.selected !== null) {
        if (this.officeOptions.length === 0) {
          this.selected = { officeName: this.selected }
        }
        this.office.name = this.selected.officeName
        this.office.phone = this.selected.officePhone
        this.office.fax = this.selected.officeFax
        this.office.officeId = this.selected.officeID
        this.office.address = this.selected.officeAddress
      } else {
        this.selected = {}
        this.officeOptions = []
        this.office.name = ''
        this.office.phone = ''
        this.office.fax = ''
        this.office.officeId = ''
        this.office.address = ''
      }
    },
    saveOffice() {
      this.showToast('Coming soon..', 'CheckCircleIcon', 'Working on it..', 'warning', 'bottom-right')
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
