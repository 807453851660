<template>
  <div class="page">
    <b-card>
      <b-alert
        v-if="errMsg.length"
        variant="warning"
        show
      >
        <div class="alert-body">
          <feather-icon
            class="mr-1"
            icon="AlertOctagonIcon"
          />
          {{ errMsg }}
        </div>
      </b-alert>
      <b-tabs
        v-model="tabIndex"
        vertical
        class="editContainer"
      >
        <!-- ******************************************** User ***************************************** -->
        <b-tab
          title="User"
          class="profileTab"
        >
          <User :user="user" />
        </b-tab>

        <!-- ******************************************** Account ***************************************** -->
        <b-tab
          title="Account"
          class="profileTab"
        >
          <Account
            :account="account"
            :user="user"
          />
        </b-tab>

        <!-- *********************************** API INTEGRATION ************************************ -->

        <b-tab
          v-if="getClient.memberType !== 'superuser'"
          title="Integration"
          class="apiTab"
        >
          <Integration
            :api-key="apiKey"
          />
        </b-tab>

        <!-- *********************************** Office Information ************************************ -->
        <b-tab
          v-if="getClient.memberType !== 'superuser'"
          title="Office"
          class="profileTab"
        >
          <Office
            :selected-office="selectedOffice"
            :office="office"
          />
        </b-tab>
        <!-- <b-tab
          v-if="getClient.memberType !== 'superuser'"
          title="Social Media"
          class="socialMediaTab"
        >
          <b-tabs>
            <b-tab title="Facebook">Coming Soon...</b-tab>
            <b-tab title="Instagram">Coming Soon...</b-tab>
          </b-tabs>
        </b-tab> -->
        <!-- ********************************** Billing Information ******************************* -->
        <b-tab
          v-if="getClient.memberType !== 'superuser'"
          title="Billing"
          class="profileTab"
        >
          <BillingCardList
            :list="billingInfo"
          />
        </b-tab>

        <!-- ****************************** subscription Information ********************************* -->
        <b-tab
          v-if="getClient.memberType !== 'superuser'"
          title="Subscription"
          class="profileTab"
        >
          <!-- class=" col-lg-6 col-md-12 col-sm-12 col-xs-12 " -->
          <div>
            <h3 class="tabTitle">
              Subscription Information</h3>
            <SubscriptionView
              v-if="!showSubscriptionUpdate"
              :history="currentPlan"
              :option="subscriptionOption"
              @onUpdateClick="showSubscriptionUpdate = true"
            />
            <SubscriptionForm
              v-else
              :id="selectedSubscriptionID"
              :current-plan="currentPlan"
              @onCancelClick="showSubscriptionUpdate = false"
            />
          </div>
        </b-tab>

        <!-- ******************************************** reset Password ********************************************-->
        <b-tab
          title="Reset Password"
          class="profileTab"
        >
          <Password />
        </b-tab>
      </b-tabs>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BAlert,
} from 'bootstrap-vue'

import User from './components/User.vue'
import Account from './components/Account.vue'
import Integration from './components/Integration.vue'
import Office from './components/Office.vue'
// import BillingForm from '../components/BillingForm.vue'
// import BillingView from '../components/BillingView.vue'
import BillingCardList from '../components/Billing/BillingCardList.vue'
import SubscriptionForm from '../components/Subscription/SubscriptionForm.vue'
import SubscriptionView from '../components/Subscription/SubscriptionView.vue'
import Password from './components/Password.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BAlert,
    User,
    Account,
    Integration,
    Office,
    // BillingForm,
    BillingCardList,
    // BillingView,
    SubscriptionForm,
    SubscriptionView,
    Password,
  },
  data() {
    return {
      user: {
        token: '',
        name: '',
        email: '',
        phone: '',
        agentId: '',
        isAdmin: false,
        accountType: '',
      },
      account: {
        name: '',
        phone: '',
        accountType: 'Team',
        googleAnalytics: '',
      },
      office: {
        name: '',
        phone: '',
        fax: '',
        officeId: '',
        address: '',
      },
      apiKey: '',
      selectedOffice: {},
      billingInfo: [],
      currentPlan: [],
      selectedSubscriptionID: '',
      showBilliingUpdate: false,
      showSubscriptionUpdate: false,
      subscriptionOption: {},
      tabIndex: 0,
      errMsg: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    getClient() {
      return this.$store.state.clientInfo
    },
  },
  created() {
    if (this.$route.hash) {
      switch (this.$route.hash) {
        case '#Billing':
          this.tabIndex = 4
          break
        default: this.tabIndex = 0
      }
    }

    this.user.token = localStorage.getItem('token')

    if (this.user.token) {
      this.getUserProfile()
      this.getSuitablePlan()
    }
  },
  methods: {
    getUserProfile() {
      this.$store.dispatch('users/getProfile').then(response => {
        console.log(response)
        if (response.code === 200) {
          const { data } = response

          this.apiKey = data.apikey

          // user
          this.user.name = data.user.name
          this.user.phone = data.user.phone
          this.user.email = data.user.email
          this.user.agentId = data.user.agent_mls_loginName
          this.user.isAdmin = data.user.isAdmin !== 0
          this.user.website = data.user.website

          // account
          this.account.name = data.account.name
          this.account.phone = data.account.primary_phone
          this.account.accountType = data.account.account_type
          this.account.googleAnalytics = data.account.google_analytics
          this.account.profilePic = data.account.profile_image
          this.account.email = data.account.primary_email
          this.account.website = data.account.website

          // office
          this.office.name = data.office.officeName
          this.office.phone = data.office.officePhone
          this.office.fax = data.office.officeFax
          this.office.officeId = data.office.officeID
          this.office.address = data.office.officeAddress
          this.selectedOffice = data.office

          // subscription
          if (data.currentPlan) {
            this.currentPlan = data.currentPlan
            this.selectedSubscriptionID = data.currentPlan[0].stripe_plan
          }

          // billing
          this.billingInfo = data.billing

          // if (data.billing && data.billing.length > 0) {
          //   const [payment] = data.billing

          //   this.billingInfo.card_number = '**** **** **** ' + payment.card.last4
          //   this.billingInfo.card_type = payment.card.brand
          //   this.billingInfo.exp_date = payment.card.exp_month + '/' + payment.card.exp_year.toString().substring(2)
          // }
        } else {
          this.errMsg = response.message
        }
      }).catch(err => {
        console.error(err)
      })
    },
    getSuitablePlan() {
      this.$store.dispatch('billings/getSuitablePlan').then(response => {
        console.log(this.subscriptionOption)
        this.subscriptionOption = response.data
      }).catch(err => {
        console.error(err)
      })
    },
    reqUpdate(tickettitle, cat, subCat) {
      this.$router.push({
        name: 'Create Ticket',
        params: {
          title: tickettitle,
          category: cat,
          subCategory: subCat,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.editContainer {
   .nav-tabs .nav-link {
    display: block;
    text-align: left;
  }

  .profileTab {

  > span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.singleMethod {
  span {
    display: block;
    text-transform: capitalize;
  }
}

.custom-file-input {
  z-index: 2 !important;
  cursor: pointer;
}
.saveBtn {
  display: block;
  margin-top: 25px;
  text-transform: capitalize;
}

.saveBtnContainer  {
  margin-top: 25px;
}

.txtCap {
  text-transform: capitalize;
}

.tabTitle {
  margin-bottom: 2rem;
}

.userTable {
  th {
    padding-right: 10px;
    padding-bottom: 10px;
  }
  td {
    padding-bottom: 10px;
    padding-right: 35px;
  }
}

.editPayment {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  height: 2.714rem;
  display: flex;
  .__PrivateStripeElement {
    width: 100%;
    iframe {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.accoutProfilePic {
  display: flex;
  margin: 50px auto;
}

.iconDanger {
  color: red;
}
}
</style>
