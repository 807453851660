<template>
  <validation-observer
    ref="passwordForm"
    #default="{invalid}"
  >
    <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
      <div>
        <h3 class="tabTitle">
          Reset Password</h3>
        <b-form @submit.prevent="resetPassword">
          <b-form-group
            label="Current Password"
            label-for="current-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required|min:8"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="current-password"
                  v-model="currentPassword"
                  class="form-control-merge"
                  name="current-password"
                  :state="errors.length > 0 ? false:null"
                  :type="passwordFieldType"
                  autocomplete="on"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    :class="errors.length > 0 ? 'iconDanger':null"
                    @click="togglePasswordVisibility('password')"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="New Password"
            label-for="new-password"
          >
            <validation-provider
              #default="{ errors }"
              name="New Password"
              rules="required|min:8"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="new-password"
                  v-model="newPassword"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="new-password"
                  :type="newPasswordFieldType"
                  autocomplete="on"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="newPasswordToggleIcon"
                    :class="errors.length > 0 ? 'iconDanger':null"
                    @click="togglePasswordVisibility('new')"
                  />
                </b-input-group-append>
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider></b-form-group>
          <b-form-group
            label="Confirm Password"
            label-for="confirm-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password Confirm"
              rules="required|password:@New Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="confirm-password"
                  v-model="confirmPassword"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="confirm-password"
                  :type="confirmPasswordFieldType"
                  autocomplete="on"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmPasswordToggleIcon"
                    :class="errors.length > 0 ? 'iconDanger':null"
                    @click="togglePasswordVisibility('confirm')"
                  />
                </b-input-group-append>
              </b-input-group> <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider></b-form-group>
          <div class="saveBtnContainer">
            <b-button
              type="submit"
              class="saveBtn"
              variant="primary"
              :disabled="invalid || newPassword != confirmPassword || newPassword.length < 8"
            >
              reset
            </b-button>
          </div>

        </b-form>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BForm,
  // BForm,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match',
})

export default {
  components: {
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetPassword() {
      const data = {
        current_password: this.currentPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.confirmPassword,
      }
      this.$store.dispatch('users/resetPassword', data).then(response => {
        console.log(response)
        if (response.code === 200) {
          this.currentPassword = ''
          this.newPassword = ''
          this.confirmPassword = ''
          this.showToast('Password reset', 'CheckCircleIcon', 'Password has been reset successfully', 'success', 'bottom-right')
        } else {
          this.showToast('Failed', 'AlertCircleIcon', response.message, 'danger', 'bottom-right')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
