<template>
  <b-form-group
    label="API Key"
    label-for="api-key"
    class=" col-lg-6 col-md-12 col-sm-12 col-xs-12 "
  >
    <validation-provider
      #default="{ errors }"
      name="api-key"
    >
      <b-input-group>
        <b-form-input
          id="api-key"
          v-model="currentApiKey"
          :state="errors.length > 0 ? false:null"
          class="form-control-merge txtCap"
          name=""
          readonly
        />
        <b-input-group-append>
          <b-button
            v-b-modal.refresh-api-modal
            type="submit"
            variant="outline-primary"
          >
            <!-- class="userUpdateBtn" -->
            <!-- @click="reqUpdate('Request accout type update', 'Account', 'switch_account_plan')" -->
            Refresh API
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <div>
      <label class="d-block mt-1">Facebook</label>
      <FBButton />
      <!-- <b-button
        variant="outline-primary"
        class="facebookBtn"
      >
        <feather-icon icon="FacebookIcon" />
        Connect with Facebook
      </b-button> -->
    </div>

    <validation-observer
      ref="apiRefreshForm"
      #default="{invalid}"
    >
      <b-modal
        id="refresh-api-modal"
        ref="api-modal"
        title="Refresh API Key"
        centered
        ok-variant="outline-primary"
        cancel-variant="outline-danger"
        @ok="e => handleRefreshOk(e, invalid)"
      >
        <form
          @submit.stop.prevent="handleRefreshSubmit(invalid)"
        >

          <b-form-group
            label="Enter your Password to refresh your API Key"
            label-for="password"
            invalid-feedback="Password id required"
            :state="!invalid"
          >
            <validation-provider
              #default="{ errors }"
              name="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="passwordToReloadApi"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility('password')"
                  />
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </form>
      </b-modal>
    </validation-observer>
  </b-form-group>

</template>

<script>
import {
  BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'

// import fbButton
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import FBButton from '../../components/Social/FBButton.vue'

// props로 받고 response값 emit으로 올리기
export default {
  components: {
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    FBButton,
  },
  mixins: [togglePasswordVisibility],
  props: {
    apiKey: String,
  },
  data() {
    return {
      required,
      passwordToReloadApi: '',
      currentApiKey: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    apiKey: {
      deep: true,
      handler() {
        this.currentApiKey = this.apiKey
      },
    },
  },
  methods: {
    handleRefreshOk(event, invalid) {
      event.preventDefault()
      this.handleRefreshSubmit(invalid)
    },
    handleRefreshSubmit(invalid) {
      if (!invalid) {
        const data = {
          password: this.passwordToReloadApi,
        }

        this.$store.dispatch('users/refreshAPI', data).then(response => {
          if (response.code === 200) {
            // should emit here ?
            // this.apiKey = response.data.apikey
            this.currentApiKey = response.data.apikey
            this.showToast('API key Refreshed', 'CheckCircleIcon', 'API Key has been refreshed successfully', 'success', 'bottom-right')
            this.$refs['api-modal'].hide()
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
