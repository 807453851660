<template>
  <validation-observer
    ref="billingForm"
    #default="{invalid}"
  >
    <div>
      <BillingInputs
        :err-msg="cardRegisterErr"
        @update-data="updateBilling"
      />
      <div class="d-flex justify-content-end">
        <b-button
          class="mr-1"
          variant="outline-danger"
          @click="$store.commit('SET_IS_ADD_CARD', false)"
        >
          Cancel
        </b-button>
        <b-button
          variant="outline-primary"
          :disabled="!isBillingCardComplete || invalid ? true : false"
          @click="submit(invalid)"
        >
          Save
        </b-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import BillingInputs from './BillingInputs.vue'

export default {
  components: {
    ValidationObserver,
    BButton,
    BillingInputs,
  },
  data() {
    return {
      billingInformation: {
        isCardComplete: false,
      },
      cardRegisterErr: '',
    }
  },
  computed: {
    isBillingCardComplete() {
      return this.billingInformation.isCardComplete
    },
  },
  methods: {
    async submit(invalid) {
      if (invalid) {
        // eslint-disable-next-line no-useless-return
        return
      }

      const cardHolderName = this.billingInformation.updatedCardHolderName
      const clientSecret = this.billingInformation.client_secret

      const { setupIntent, error } = await this.billingInformation.stripe.confirmCardSetup(
        clientSecret, {
          payment_method: {
            card: this.billingInformation.cardElement,
            billing_details: {
              name: cardHolderName,
              address: this.billingInformation.billingAddress,
            },
          },
        },
      )

      if (error) {
        if (error.message) {
          this.cardRegisterErr = error.message
        }
      } else {
        this.$store.dispatch('users/setStripe', setupIntent.payment_method).then(response => {
          console.log(response)
          if (response.code === 200) {
            this.$store.commit('SET_IS_ADD_CARD', false)
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    updateBilling(data) {
      this.billingInformation = data
    },
  },
}
</script>

<style>
</style>
