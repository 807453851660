// We haven't added icon's computed property because it makes this mixin coupled with UI
export const formatPhone = {
  data() {
    return {
      formatted: '',
    }
  },
  computed: {
    formatPhone: {
      get() {
        return this.formatted
      },
      set(value) {
        const cleaned = ('' + value).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          this.formatted = match[1] + '.' + match[2] + '.' + match[3]
        } else {
          this.formatted = value
        }
      },
    },
  },
  methods: {
    setPhoneNumber(phoneNumber) {
      this.formatted = phoneNumber
    },
  },
}

export const _ = null
