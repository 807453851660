<template>
  <div class="paymentMethodList">
    <div class="d-flex justify-content-between mb-3">
      <b-button
        v-if="getSignedInClient.memberType !== 'superuser'"
        variant="outline-secondary"
        @click="$store.commit('SET_IS_ADD_CARD', true)"
      >
        <feather-icon icon="PlusIcon" />
        Add New Card
      </b-button>
    </div>
    <div
      v-if="paymentMethods && paymentMethods.length > 0"
      class="paymentMethodContainer"
    >

      <div class="row d-flex flex-wrap">
        <b-card
          v-for="(singlePaymentMethod, index) in paymentMethods"
          :key="'singleCard' + index"
          v-b-modal.update-default-modal
          class="singleCard w-100 pt-2 pb-2 col-4 m-1"
          :class="singlePaymentMethod.default && 'default'"
        >
          <div
            class="d-flex mb-2"
            :class="singlePaymentMethod.default ? 'justify-content-between' : 'justify-content-end'"
          >
            <b-avatar
              v-if="singlePaymentMethod.default"
              size="36"
              variant="primary"
              class="defaultStarIcon"
            >
              <feather-icon
                size="18"
                icon="StarIcon"
              />

            </b-avatar>
            <div class="d-flex">
              <b-button
                v-if="!singlePaymentMethod.default && getSignedInClient.memberType !== 'superuser'"
                variant="outline-primary"
                class="align-items-center makeDefaultBtn mr-1"
                @click="onMakeDefaultClick(singlePaymentMethod)"
              >
                <feather-icon
                  size="18"
                  icon="StarIcon"
                  class="mr-1"
                />Make a default</b-button>
              <b-dropdown
                v-if="getSignedInClient.memberType !== 'superuser'"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template
                  v-slot:button-content
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="20"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="onEditClick(singlePaymentMethod)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="onDeleteClick(singlePaymentMethod)">
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="cardInfo">
            <h3>{{ singlePaymentMethod.card.card_type }}</h3>
            <h3> {{ singlePaymentMethod.card.card_number }}</h3>
            <h5 v-if="singlePaymentMethod.billing_address"> {{ singlePaymentMethod.billing_address.address_line_1 }} {{ singlePaymentMethod.billing_address.address_line_2 }}
              <br> {{ singlePaymentMethod.billing_address.address_city }} {{ singlePaymentMethod.billing_address.address_province }} {{ singlePaymentMethod.billing_address.address_postal_code }}</h5>
            <h5>Expires {{ getExpMonth(singlePaymentMethod.card.expired_date_month) }}/{{ singlePaymentMethod.card.expired_date_year }}</h5>
          </div>
        </b-card>

      </div>
    </div>
    <b-alert
      v-else
      variant="warning"
      show
    >
      <div class="alert-body">
        <feather-icon
          class="mr-1"
          icon="AlertOctagonIcon"
        /> No Billing Payment Method
      </div>
    </b-alert>
    <AddCard />
    <EditCard
      v-if="paymentMethods && paymentMethods.length > 0 && Object.keys(targetCardToEdit).length"
      :prop-payment-method="targetCardToEdit"
    />
    <DeleteCard :target-card="targetCardToDelete" />

  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BCard, BButton, BAvatar, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddCard from './AddCard.vue'
import EditCard from './EditCard.vue'
import DeleteCard from './DeleteCard.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BAvatar,
    AddCard,
    EditCard,
    DeleteCard,
    BAlert,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      paymentMethods: [],
      selectedDefault: {},
      targetIndex: 0,
      targetCard: {},
      targetCardToEdit: {},
      targetCardToDelete: {},
    }
  },
  computed: {
    getTargetCard() {
      return this.list[this.getTargetIndex]
    },
    getTargetIndex() {
      return this.targetIndex
    },
    getSignedInClient() {
      return this.$store.state.clientInfo
    },
  },
  watch: {
    list: {
      deep: true,
      handler(newVal) {
        this.paymentMethods = newVal
      },
    },
  },
  created() {
    this.paymentMethods = this.list ? this.list : []
  },
  methods: {
    formatExpDate(date) {
      if (date.length === 4) {
        return '0' + date
      }

      return date
    },
    onEditClick(target) {
      this.targetCardToEdit = target
      this.$store.commit('SET_IS_EDIT_CARD', true)
    },
    onDeleteClick(target) {
      this.targetCardToDelete = target
      this.$store.commit('SET_IS_DELETE_CARD', true)
    },
    onSingleCardClick(method) {
      this.targetCard = method
    },
    onMakeDefaultClick(target) {
      if (this.getSignedInClient.memberType === 'superuser') {
        console.log('super user')
      } else {
        const data = {
          payment_id: target.card.payment_id,
          type: 'default',
          address: {
            address_line_1: target.billing_address.address_line_1,
            address_line_2: target.billing_address.address_line_2,
            address_city: target.billing_address.address_city,
            address_province: target.billing_address.address_province,
            address_country: target.billing_address.address_country,
            address_postal_code: target.billing_address.address_postal_code,
          },
          expire:
          {
            month: target.card.expired_date_month,
            year: target.card.expired_date_year,
          },
        }

        this.$store.dispatch('billings/updatePaymentMethod', data)
          .then(response => {
            if (response.code === 200) {
              this.paymentMethods.forEach(element => {
                if (target.card.payment_id === element.card.payment_id) {
                  // eslint-disable-next-line no-param-reassign
                  target.default = true
                } else {
                  // eslint-disable-next-line no-param-reassign
                  element.default = false
                }
              })

              this.showToast('Success', 'CheckCircleIcon', response.message, 'success', 'bottom-right')
            } else {
              this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
            }
          })
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
    getExpMonth(month) {
      if (month.toString().length === 1) {
        return '0' + month
      }

      return month
    },
  },
}
</script>

<style>
  .paymentMethodList .settingContainer {
    margin-bottom: 1.53rem;
  }

  .paymentMethodList .dropdown-toggle {
    padding: 0 !important;
  }

  .paymentMethodList .custom-control {
    z-index: unset !important;
  }
</style>
