<template>
  <validation-observer
    ref="accountForm"
    #default="{invalid}"
  >
    <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
      <div>
        <h3 class="tabTitle">
          Account Information</h3>
        <b-form-group
          label="Profile Image"
          label-for="account-profile-pic"
        >
          <b-avatar
            size="6rem"
            variant="light-primary"
            :src="previewProfilePic"
            class="accoutProfilePic"
          />
          <b-form-file
            ref="accountProfilePicRef"
            v-model="tempImg"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            :disabled="!user.isAdmin"
            accept="image/*"
            @change="onSelectProfilcePic"
          />
          <!-- v-model="account.profilePic" -->
        </b-form-group>
        <b-form-group
          label="Account Name"
          label-for="account-name"
        >
          <validation-provider
            #default="{ errors }"
            name="Account Name"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="account-name"
                v-model="account.name"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="account-name"
                :readonly="!user.isAdmin"
              />
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              id="email-account"
              v-model="account.email"
              class="form-control-merge"
              name="email"
              :readonly="!user.isAdmin"
            />
          </b-input-group>

        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="phone-account"
                v-model="formatPhone"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="phone"
                :readonly="!user.isAdmin"
              />
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Account Website"
          label-for="account-website"
        >
          <validation-provider
            #default="{ errors }"
            name="Account Website"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="account-website"
                v-model="account.website"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="account-website"
                :readonly="!user.isAdmin"
              />
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Google Analytics Key"
          label-for="google-analytics"
        >
          <validation-provider
            #default="{ errors }"
            name="Google Analytics Key"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="google-analytics"
                v-model="account.googleAnalytics"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="google-analytics"
                :readonly="!user.isAdmin"
              />
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Account Type"
          label-for="account-type"
        >
          <validation-provider
            #default="{ errors }"
            name="account-type"
            rules="required"
          >
            <b-input-group>
              <b-form-input
                id="account-type"
                v-model="account.accountType"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge txtCap"
                name=""
                readonly
              />

              <b-input-group-append>
                <b-button
                  type="submit"
                  class="userUpdateBtn"
                  variant="outline-primary"
                  @click="reqUpdate('Request accout type update', 'Account', 'switch_account_plan')"
                >
                  Request Update
                </b-button>
              </b-input-group-append>

            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="row">
          <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <b-dropdown
              v-if="user.isAdmin"
              class="w-100 mt-2"
              text="Switch Account"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="(item, index) in getOtherAccounts"
                :key="index"
                link-class="d-flex align-items-center"
                @click="switchAccount(item)"
              >
                <span style="text-transform:Capitalize">{{ item.account_name }} ({{ item.account_type }})</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div
            v-if="account.accountType === 'team' || account.accountType === 'brokerage'"
            class=" col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <b-button
              class="w-100 mt-2"
              type="submit"
              variant="outline-primary"
              style="text-transform: capitalize"
              :href="'/' + getManageLink(account.accountType) +'/'"
            >
              Manage {{ account.accountType }}
            </b-button>
          </div>
        </div>

        <div class="saveBtnContainer">
          <b-button
            type="submit"
            class="saveBtn"
            :disabled="invalid"
            variant="outline-primary"
            @click="saveAccount(invalid)"
          >
            save account
          </b-button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormFile, BAvatar, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// import { testGlobalFunction } from '@core/mixins/ui/forms'
import { formatPhone } from '@core/mixins/ui/phone'

export default {
  components: {
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BInputGroupAppend,
  },
  mixins: [formatPhone],
  props: {
    account: Object,
    user: Object,
  },
  data() {
    return {
      required,
      email,
      previewProfilePic: '',
      tempImg: null,
    }
  },
  computed: {
    getOtherAccounts() {
      return this.$store.state.clientInfo.otherAccount
    },
  },
  watch: {
    account: {
      deep: true,
      handler() {
        this.previewProfilePic = this.account.profilePic
        this.setPhoneNumber(this.account.phone)
      },
    },
  },
  methods: {
    saveAccount(invalid) {
      const formData = new FormData()

      if (this.tempImg !== null) {
        const imagefile = this.tempImg

        formData.append('profile_image', imagefile)
      }

      console.log(this.account.googleAnalytics)
      formData.append('name', this.account.name)
      formData.append('phone', this.formatPhone)
      formData.append('email', this.account.email)
      formData.append('website', this.account.website)
      formData.append('google_analytics', this.account.googleAnalytics)

      if (!invalid) {
        this.$store.dispatch('users/updateAccount', formData).then(response => {
          if (response.code === 200) {
            this.showToast('Account Updated', 'CheckCircleIcon', 'Account has been updated successfully', 'success', 'bottom-right')
          } else {
            console.log(response)
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    reqUpdate(tickettitle, cat, subCat) {
      this.$router.push({
        name: 'Create Ticket',
        params: {
          title: tickettitle,
          category: cat,
          subCategory: subCat,
        },
      })
    },
    getManageLink(type) {
      let link = ''
      if (type === 'team') {
        link = 'teams'
      } else if (type === 'brokerage') {
        link = 'brokerage'
      }
      return link
    },
    onSelectProfilcePic(e) {
      const image = e.target.files[0]

      if (image.size < 1024 * 100) {
        const reader = new FileReader()

        reader.readAsDataURL(image)

        reader.onload = event => {
          this.previewProfilePic = event.target.result
          this.tempImg = image
        }
      } else {
        this.$refs.accountProfilePicRef.reset()
        // this.previewProfilePic = this.originProfilePic
        this.showToast('Upload Failed', 'AlertCircleIcon', 'File exceeds the maximum file size (500KB)', 'danger', 'bottom-right')
      }
    },
    switchAccount(item) {
      this.$store.dispatch('users/switchLogout', item.unique_id).then(response => {
        localStorage.removeItem('token')
        this.$store.commit('SET_LOGIN_TIME', '')
        this.$store.commit('setClientInfo', {})
        this.$store.dispatch('users/switchLogin', response.data).then(switchResponse => {
          localStorage.setItem('token', switchResponse.data.token)
          this.$store.commit('SET_LOGIN_TIME', new Date().getTime())
          this.$store.commit('setClientInfo', switchResponse.data)
          window.location.href = '/'
        }).catch(err => {
          console.error(err)
        })
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
