var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"accountForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12 "},[_c('div',[_c('h3',{staticClass:"tabTitle"},[_vm._v(" Account Information")]),_c('b-form-group',{attrs:{"label":"Profile Image","label-for":"account-profile-pic"}},[_c('b-avatar',{staticClass:"accoutProfilePic",attrs:{"size":"6rem","variant":"light-primary","src":_vm.previewProfilePic}}),_c('b-form-file',{ref:"accountProfilePicRef",attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","disabled":!_vm.user.isAdmin,"accept":"image/*"},on:{"change":_vm.onSelectProfilcePic},model:{value:(_vm.tempImg),callback:function ($$v) {_vm.tempImg=$$v},expression:"tempImg"}})],1),_c('b-form-group',{attrs:{"label":"Account Name","label-for":"account-name"}},[_c('validation-provider',{attrs:{"name":"Account Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"account-name","state":errors.length > 0 ? false:null,"name":"account-name","readonly":!_vm.user.isAdmin},model:{value:(_vm.account.name),callback:function ($$v) {_vm.$set(_vm.account, "name", $$v)},expression:"account.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"email-account","name":"email","readonly":!_vm.user.isAdmin},model:{value:(_vm.account.email),callback:function ($$v) {_vm.$set(_vm.account, "email", $$v)},expression:"account.email"}})],1)],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone-account","state":errors.length > 0 ? false:null,"name":"phone","readonly":!_vm.user.isAdmin},model:{value:(_vm.formatPhone),callback:function ($$v) {_vm.formatPhone=$$v},expression:"formatPhone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Account Website","label-for":"account-website"}},[_c('validation-provider',{attrs:{"name":"Account Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"account-website","state":errors.length > 0 ? false:null,"name":"account-website","readonly":!_vm.user.isAdmin},model:{value:(_vm.account.website),callback:function ($$v) {_vm.$set(_vm.account, "website", $$v)},expression:"account.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Google Analytics Key","label-for":"google-analytics"}},[_c('validation-provider',{attrs:{"name":"Google Analytics Key"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"google-analytics","state":errors.length > 0 ? false:null,"name":"google-analytics","readonly":!_vm.user.isAdmin},model:{value:(_vm.account.googleAnalytics),callback:function ($$v) {_vm.$set(_vm.account, "googleAnalytics", $$v)},expression:"account.googleAnalytics"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Account Type","label-for":"account-type"}},[_c('validation-provider',{attrs:{"name":"account-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control-merge txtCap",attrs:{"id":"account-type","state":errors.length > 0 ? false:null,"name":"","readonly":""},model:{value:(_vm.account.accountType),callback:function ($$v) {_vm.$set(_vm.account, "accountType", $$v)},expression:"account.accountType"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"userUpdateBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request accout type update', 'Account', 'switch_account_plan')}}},[_vm._v(" Request Update ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12"},[(_vm.user.isAdmin)?_c('b-dropdown',{staticClass:"w-100 mt-2",attrs:{"text":"Switch Account","variant":"outline-primary"}},_vm._l((_vm.getOtherAccounts),function(item,index){return _c('b-dropdown-item',{key:index,attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.switchAccount(item)}}},[_c('span',{staticStyle:{"text-transform":"Capitalize"}},[_vm._v(_vm._s(item.account_name)+" ("+_vm._s(item.account_type)+")")])])}),1):_vm._e()],1),(_vm.account.accountType === 'team' || _vm.account.accountType === 'brokerage')?_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12"},[_c('b-button',{staticClass:"w-100 mt-2",staticStyle:{"text-transform":"capitalize"},attrs:{"type":"submit","variant":"outline-primary","href":'/' + _vm.getManageLink(_vm.account.accountType) +'/'}},[_vm._v(" Manage "+_vm._s(_vm.account.accountType)+" ")])],1):_vm._e()]),_c('div',{staticClass:"saveBtnContainer"},[_c('b-button',{staticClass:"saveBtn",attrs:{"type":"submit","disabled":invalid,"variant":"outline-primary"},on:{"click":function($event){return _vm.saveAccount(invalid)}}},[_vm._v(" save account ")])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }