var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"officeForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12 "},[_c('div',[_c('h3',{staticClass:"tabTitle"},[_vm._v(" Office Information")]),_c('b-form-group',{attrs:{"label":"Office Name","label-for":"office-name"}},[_c('validation-provider',{attrs:{"name":"Office Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"get-option-label":function (option) { return option.officeName; },"options":_vm.getOfficeResOptions,"filterable":false,"taggable":"","disabled":""},on:{"search":_vm.onSearch,"input":_vm.setOffice},scopedSlots:_vm._u([{key:"option",fn:function(officeOptions){return [_c('div',{staticClass:"d-center"},[_c('p',[_vm._v(_vm._s(officeOptions.officeName))]),(officeOptions.officeShortName && officeOptions.officePhone)?_c('p',[_vm._v(" "+_vm._s(officeOptions.officeShortName)+" | "+_vm._s(officeOptions.officePhone)+" ")]):_vm._e()])]}},{key:"selected-option",fn:function(officeOptions){return [_c('div',{staticClass:"selected d-center"},[_c('p',[_vm._v(_vm._s(officeOptions.officeName)+" "),(Object.keys(officeOptions).length && officeOptions.officeShortName)?_c('span',[_vm._v(" | "+_vm._s(officeOptions.officeShortName)+" ")]):_vm._e()])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-options"},[_c('span',[_vm._v(" type to search for offices ")])])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone-office","state":errors.length > 0 ? false:null,"name":"phone","readonly":""},model:{value:(_vm.office.phone),callback:function ($$v) {_vm.$set(_vm.office, "phone", $$v)},expression:"office.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Fax","label-for":"fax"}},[_c('validation-provider',{attrs:{"name":"fax","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"fax","state":errors.length > 0 ? false:null,"name":"fax","readonly":""},model:{value:(_vm.office.fax),callback:function ($$v) {_vm.$set(_vm.office, "fax", $$v)},expression:"office.fax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Address","label-for":"office-address"}},[_c('validation-provider',{attrs:{"name":"office-address","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"officeAddress","state":errors.length > 0 ? false:null,"name":"office-address","readonly":""},model:{value:(_vm.office.address),callback:function ($$v) {_vm.$set(_vm.office, "address", $$v)},expression:"office.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }