<template>
  <div class="component">
    <b-button
      v-if="!isConnectedWithFB"
      variant="outline-primary"
      class="facebookBtn"
      @click="connectWithFacebook"
    >
      <feather-icon icon="FacebookIcon" />
      Connect with Facebook
    </b-button>

    <b-button
      v-else
      variant="outline-primary"
      class="facebookBtn"
      @click="disconnectFB"
    >
      <feather-icon icon="FacebookIcon" />
      Disconnect
    </b-button>

    <b-modal
      id="fb-select-page-modal"
      title="Select Page"
      @ok="handleOk"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(single, index) in pageList"
          :key="'single-page-' + index"
          button
          @click="onSinglePageClick(single.id, single.access_token)"
        >
          <p>Page Name: {{ single.name }}</p>
          <!-- <p>Page ID: {{ single.id }}</p> -->
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BModal,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      isConnected: false,
      userID: '2345898755540506',
      userAccessToken: 'EAAHyMseFslcBAGclpPgCHZBjqQSskb5PaElyHrZCkZCo6xs6hjqzYMqpwbEZBPKETqwgZAPht1kZBjHDKZAg9eB9QmqQVcZBfCkPt0dvZA49IiCfvMVsCDp29i5Ngz1GTMOIZA8IT9e7jgn9i96ASVqHHgPeIpRJw1BQmOZB8kaiCzZA6Ip4WdCM1Pkf',
      pageID: '',
      pageAccessToken: '',
      pageList: [
        {
          name: 'Testing',
          id: '104575015265619',
          access_token: 'EAAHyMseFslcBAHwrdY2uqqZC1O2enxWkxczvsj3ud2bC7z2ZCLedDID4IymLZBUl2KZBCA80CkghAFKk8jft1ctLEnnZCzc4zIejZCTOu1hqTAS0OE5TuRiztKC5wt6nzoUnxzzy5MKxArXEDtenBlUqj4d3lQYI6jc8khZAq7oZCMpz8EkLV1RZC',
        },
        {
          name: 'Testing2',
          id: '104575015265619',
          access_token: 'EAAHyMseFslcBAHwrdY2uqqZC1O2enxWkxczvsj3ud2bC7z2ZCLedDID4IymLZBUl2KZBCA80CkghAFKk8jft1ctLEnnZCzc4zIejZCTOu1hqTAS0OE5TuRiztKC5wt6nzoUnxzzy5MKxArXEDtenBlUqj4d3lQYI6jc8khZAq7oZCMpz8EkLV1RZC',
        },
      ],
    }
  },
  computed: {
    isConnectedWithFB() {
      return this.isConnected
    },
  },
  created() {
    console.log('facebook created')

    // get fb info from backend
    this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    this.initFacebook()
  },
  methods: {
    async connectWithFacebook() {
      this.$bvModal.show('fb-select-page-modal')

      // const vm = this

      // window.FB.login(
      //   response => {
      //     console.log(response)
      //     if (response.authResponse) {
      //       vm.userID = response.authResponse.userID
      //       vm.userAccessToken = response.authResponse.accessToken
      //       vm.getPageList()
      //       vm.$bvModal.show('fb-select-page-modal')
      //       this.showToast(
      //         'Success',
      //         'AlertCircleIcon',
      //         'Connected',
      //         'danger',
      //         'bottom-right',
      //       )
      //       // Now you can redirect the user or do an AJAX request to
      //       // a PHP script that grabs the signed request from the cookie.
      //     } else {
      //       this.showToast(
      //         'Failed',
      //         'AlertCircleIcon',
      //         'User cancelled login or did not fully authorize',
      //         'danger',
      //         'bottom-right',
      //       )
      //     }
      //   },
      //   { scope: 'user_posts, pages_manage_posts, instagram_basic, pages_show_list' },
      // )
      return false
    },
    disconnectFB() {
      window.FB.logout(async res => {
        console.log(res)
      })
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: '547774886425175',
          //   cookie: true,
          version: 'v11.0',
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      // eslint-disable-next-line no-var
      var js
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },
    getPageList() {
      axios
        .get(
          'https://graph.facebook.com/'
            + this.userID
            + '/accounts?access_token='
            + this.userAccessToken,
        )
        .then(response => {
          console.log(response)
          this.pageList = response.data.data
          //   this.pageID = response.data.data[0].id
          //   this.pageAccessToken = response.data.data[0].access_token
        })
        .catch(error => {
          console.log(error)
        })
    },
    onSinglePageClick(id, token) {
      this.pageID = id
      this.pageAccessToken = token
    },
    handleOk() {
      // send data to backend
      // ref. https://developers.facebook.com/docs/pages/publishing/
      // ref. https://www.adamboother.com/blog/automatically-posting-to-a-facebook-page-using-the-facebook-sdk-v5-for-php-facebook-api/

      const exp = new Date()
      exp.setDate(exp.getDate() + 60)

      const opt = {
        type: 'facebook',
        value: {
          user_id: this.userID,
          user_access_token: this.userAccessToken,
          page_id: this.pageID,
          page_access_token: this.pageAccessToken,
        },
        expired_at: exp.toISOString().replace(/T/, ' ').replace(/\..+/, ''),
      }

      const data = {
        account_id: this.$route.params.id,
        options: opt,
      }

      console.log(data)

      this.$store.dispatch('social/addSocialMedia', data).then(response => {
        console.log(response)
      }).catch(err => {
        console.error(err)
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style>
</style>
