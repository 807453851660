var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12 "},[_c('div',[_c('h3',{staticClass:"tabTitle"},[_vm._v(" Reset Password")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword($event)}}},[_c('b-form-group',{attrs:{"label":"Current Password","label-for":"current-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"current-password","name":"current-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"autocomplete":"on"},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",class:errors.length > 0 ? 'iconDanger':null,attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('password')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"New Password","label-for":"new-password"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new-password","state":errors.length > 0 ? false:null,"name":"new-password","type":_vm.newPasswordFieldType,"autocomplete":"on"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",class:errors.length > 0 ? 'iconDanger':null,attrs:{"icon":_vm.newPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('new')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm-password"}},[_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"required|password:@New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","state":errors.length > 0 ? false:null,"name":"confirm-password","type":_vm.confirmPasswordFieldType,"autocomplete":"on"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",class:errors.length > 0 ? 'iconDanger':null,attrs:{"icon":_vm.confirmPasswordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('confirm')}}})],1)],1),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"saveBtnContainer"},[_c('b-button',{staticClass:"saveBtn",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.newPassword != _vm.confirmPassword || _vm.newPassword.length < 8}},[_vm._v(" reset ")])],1)],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }