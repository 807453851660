var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"userForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:" col-lg-6 col-md-12 col-sm-12 col-xs-12"},[_c('div',[_c('h3',{staticClass:"tabTitle"},[_vm._v(" User Information")]),_c('b-form-group',{attrs:{"label":"User Name","label-for":"user-name"}},[_c('validation-provider',{attrs:{"name":"User Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"user-name","state":errors.length > 0 ? false:null,"name":"user-name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","readonly":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"userUpdateBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request agent email update', 'Account', 'change_agent_email')}}},[_vm._v(" Request Update ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone"},model:{value:(_vm.formatPhone),callback:function ($$v) {_vm.formatPhone=$$v},expression:"formatPhone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Agent ID","label-for":"agent-id"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"agent-id","name":"agent-id","readonly":""},model:{value:(_vm.user.agentId),callback:function ($$v) {_vm.$set(_vm.user, "agentId", $$v)},expression:"user.agentId"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"userUpdateBtn",attrs:{"type":"submit","variant":"outline-primary"},on:{"click":function($event){return _vm.reqUpdate('Request agent id update', 'Account', 'change_agent_id')}}},[_vm._v(" Request Update ")])],1)],1)],1),_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"website","state":errors.length > 0 ? false:null,"name":"phone"},model:{value:(_vm.user.website),callback:function ($$v) {_vm.$set(_vm.user, "website", $$v)},expression:"user.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"saveBtnContainer"},[_c('b-button',{staticClass:"saveBtn",attrs:{"type":"submit","disabled":invalid,"variant":"outline-primary"},on:{"click":function($event){return _vm.saveUser(invalid)}}},[_vm._v(" Save User ")])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }