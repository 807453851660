<template>
  <validation-observer
    ref="billingUpdateForm"
    #default="{invalid}"
  >
    <b-form-group
      label="Card Holder Name"
      label-for="card-holder-name"
      invalid-feedback="Card holder name is required"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="Card Holder Name"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="cardHolderName"
            v-model="billing.updatedCardHolderName"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="cardHolderName"
            @input="updateData"
          />
        </b-input-group>
      </validation-provider>
    </b-form-group>

    <!-- Stripe Elements Placeholder -->
    <label>Card Information</label>
    <div
      id="card-element"
      class="editPayment"
    >
      <b-spinner
        class="m-auto"
        small
        label="Spinner"
      />
    </div>
    <div class="errMsgContainer">
      {{ errMsg }}
    </div>
    <hr>
    <b-form-group
      label="Billing Address Line 1"
      label-for="card-holder-name"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="Billing Address Line 1"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="address_line_1"
            v-model="billing.billingAddress.line1"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="billingAddress1"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Billing Address Line 2"
      label-for="card-holder-name"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="Billing Address Line 2"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="address_line_2"
            v-model="billing.billingAddress.line2"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="billingAddress1"
            @input="updateData"
          />
        </b-input-group>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="City"
      label-for="city"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="City"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="city"
            v-model="billing.billingAddress.city"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Postal Code"
      label-for="postal-code"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="Postal Code"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="address_line_postal_code"
            v-model="billing.billingAddress.postal_code"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Province"
      label-for="province"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="Province"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="address_line_province"
            v-model="billing.billingAddress.state"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Country"
      label-for="country"
      :state="!invalid"
    >
      <validation-provider
        #default="{ errors }"
        name="Country"
        rules="required"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="address_line_country"
            v-model="billing.billingAddress.country"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </validation-observer>
</template>

<script>
import {
  //   BButton,
  BFormGroup, BInputGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    // BButton,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BSpinner,
  },
  props: {
    errMsg: String,
  },
  data() {
    return {
      required,
      email,
      billing: {
        stripe: null,
        cardElement: null,
        client_secret: '',
        updatedCardHolderName: '',
        billingAddress: {
          line1: '',
          line2: '',
          city: '',
          postal_code: '',
          state: '',
          country: '',
        },
        isCardComplete: false,
        cardErrMsg: '',
      },
      valueFields: {
        cardName: '',
        cardNumber: '4724 0000 0000 0000',
        cardMonth: '05',
        cardYear: '2021',
        cardCvv: '',
      },
    }
  },
  computed: {
    getPublicKey() {
      return this.$store.state.STRIPE_PUBLIC_KEY
    },
  },
  created() {
    this.loadCard()
  },
  methods: {
    loadCard() {
      // stripe
      const script = document.createElement('script')
      script.setAttribute('src', 'https://js.stripe.com/v3/')

      document.head.appendChild(script)
      this.$store.dispatch('users/createPaymentMethod').then(response => {
        if (response.code === 200) {
          this.billing.client_secret = response.data.client_secret
          this.setCard()
        }
      }).catch(err => {
        console.error(err)
      })
    },
    setCard() {
      // Set Card
      this.billing.stripe = Stripe(this.getPublicKey)

      const elements = this.billing.stripe.elements()
      this.billing.cardElement = elements.create('card')
      this.billing.cardElement.mount('#card-element')

      this.billing.cardElement.on('change', event => {
        if (event.complete) {
          this.billing.isCardComplete = true
          this.updateData()
          // enable payment button
        } else if (event.error) {
          // show validation to customer
          this.billing.cardErrMsg = event.error.message
        }
      })
    },
    submit() {
      this.$store.dispatch('billings/updatePaymentMethod').then(response => {
        if (response.code === 200) {
          console.log('do something')
        }
      }).catch(err => {
        console.error(err)
      })
    },
    updateData() {
      this.$emit('update-data', this.billing)
    },
  },
}
</script>

<style lang="scss">
.editPayment {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  height: 2.714rem;
  display: flex;
  .__PrivateStripeElement {
    width: 100%;
    iframe {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.errMsgContainer {
  width: 100%;
  font-size: 0.857rem;
  color: #ea5455;
  margin-top: 0.25rem;
}
</style>
