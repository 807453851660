<template>

  <validation-observer
    ref="userForm"
    #default="{invalid}"
  >
    <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <div>
        <h3 class="tabTitle">
          User Information</h3>
        <b-form-group
          label="User Name"
          label-for="user-name"
        >
          <validation-provider
            #default="{ errors }"
            name="User Name"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="user-name"
                v-model="user.name"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="user-name"
              />
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="email"
                v-model="user.email"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="email"
                readonly
              />

              <b-input-group-append>
                <b-button
                  type="submit"
                  class="userUpdateBtn"
                  variant="outline-primary"
                  @click="reqUpdate('Request agent email update', 'Account', 'change_agent_email')"
                >
                  Request Update
                </b-button>
              </b-input-group-append>
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="phone"
                v-model="formatPhone"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="phone"
              />
              <!-- @input="formatPhone(user.phone)" -->
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Agent ID"
          label-for="agent-id"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              id="agent-id"
              v-model="user.agentId"

              class="form-control-merge"
              name="agent-id"
              readonly
            />

            <b-input-group-append>
              <b-button
                type="submit"
                class="userUpdateBtn"
                variant="outline-primary"
                @click="reqUpdate('Request agent id update', 'Account', 'change_agent_id')"
              >
                Request Update
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Website"
          label-for="website"
        >
          <validation-provider
            #default="{ errors }"
            name="Website"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="website"
                v-model="user.website"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="phone"
              />
              <!-- @input="formatPhone(user.phone)" -->
            </b-input-group><small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="saveBtnContainer">
          <b-button
            type="submit"
            class="saveBtn"
            :disabled="invalid"
            variant="outline-primary"
            @click="saveUser(invalid)"
          >
            Save User
          </b-button>
        </div>
      </div>
    </div>

  </validation-observer>
</template>

<script>
import {
  BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { formatPhone } from '@core/mixins/ui/phone'

export default {
  components: {
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
  },
  mixins: [formatPhone],
  data() {
    return {
      required,
      email,
    }
  },
  props: {
    user: Object,
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.setPhoneNumber(this.user.phone)
      },
    },
  },
  methods: {
    saveUser(invalid) {
      const data = {
        name: this.user.name,
        website: this.user.website,
        phone: this.formatPhone,
      }

      if (!invalid) {
        this.$store.dispatch('users/updateUser', data).then(response => {
          if (response.code === 200) {
            this.showToast('User Updated', 'CheckCircleIcon', 'User has been updated successfully', 'success', 'bottom-right')
          } else {
            this.showToast('Failed', 'AlertCircleIcon', 'There was an error during the update', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    reqUpdate(tickettitle, cat, subCat) {
      this.$router.push({
        name: 'Create Ticket',
        params: {
          title: tickettitle,
          category: cat,
          subCategory: subCat,
        },
      })
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
